import React from 'react';
import ReactDOM from 'react-dom';
import styled, { ThemeProvider } from 'styled-components';
import { isBrowser } from 'utils/browser';
import breakpoints from 'utils/breakpoints';
import Paragraph from 'components/Paragraph';
import Button from 'components/Button';
import { Link } from 'gatsby';
import { dark } from 'themes';
import useAccepted from './useAccepted';

const Wrapper = styled.div`
  padding: 1.25rem;
  margin-left: 1rem;
  max-width: 454px;
  background: #fffaf3;
  border: 1px solid #e6dcce;
  border-radius: 5px;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 1000;
  display: ${({ shouldShow }) => (shouldShow ? 'block' : 'none')};

  @media (min-width: ${breakpoints.md}) {
    padding: 1.875rem;
    margin-left: 1.875rem;
    right: 1.875rem;
    bottom: 1.875rem;
  }
`;

const Title = styled.h2`
  margin: 0 0 0.5rem 0;
  font-family: Helvetica;
  font-size: 1.125rem;
  font-weight: 700;
  color: #1c2f45;
  letter-spacing: 0;
`;

const AcceptButton = styled(Button)`
  padding-left: 22px;
  padding-right: 22px;
`;

function CookiePolicy() {
  const { accepted, accept } = useAccepted();

  if (!isBrowser()) {
    return null;
  }

  return ReactDOM.createPortal(
    <ThemeProvider theme={dark}>
      <Wrapper shouldShow={!accepted}>
        <Title>We&apos;ve updated our cookies policy</Title>
        <Paragraph>
          This site uses cookies to provide you with the best user experience.
          By using our website, you accept our &nbsp;
          <Link to="/cookie-policy/" title="Cookies Policy">
            use of cookies
          </Link>
        </Paragraph>
        <AcceptButton onClick={accept}>Yes, I Accept</AcceptButton>
      </Wrapper>
    </ThemeProvider>,
    document.body
  );
}

export default CookiePolicy;
